import React from 'react'
import "./Header.css";



function Header() {
  return (
    // <div className='p-5 bg-primary flex justify-between header'>
    //      <h1 className='text-secondary text-4xl font-semibold'>Tech Swamy Kannada</h1>
    //      <h1 className='text-white text-4xl font-semibold'>S</h1>
    //     <h1 className='text-tertiary text-4xl font-semibold'>K</h1> 

        
        
    // </div>

    <header class="header" id="header">
            <nav class="nav container">
            <img src="assets/img/logo.png" alt="" class="nav__img"/>
                <a href="#" class="nav__logo">Tech Swamy Kannada</a>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item">
                            <a href="#home" class="nav__link active-link">
                                <i class='bx bx-home-alt nav__icon'></i>
                                <span class="nav__name">Home</span>
                            </a>
                        </li>
                        
                        <li class="nav__item">
                            <a href="#about" class="nav__link">
                                <i class='bx bx-user nav__icon'></i>
                                <span class="nav__name">About</span>
                            </a>
                        </li>

                        <li class="nav__item">
                            <a href="#skills" class="nav__link">
                                <i class='bx bx-book-alt nav__icon'></i>
                                <span class="nav__name">Skills</span>
                            </a>
                        </li>

                        <li class="nav__item">
                            <a href="#portfolio" class="nav__link">
                                <i class='bx bx-briefcase-alt nav__icon'></i>
                                <span class="nav__name">Portfolio</span>
                            </a>
                        </li>

                        <li class="nav__item">
                            <a href="#contactme" class="nav__link">
                                <i class='bx bx-message-square-detail nav__icon'></i>
                                <span class="nav__name">Contactme</span>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* <img src="assets/img/logo.png" alt="" class="nav__img"/> */}
            </nav>
        </header>
  )
}

export default Header